import { cn } from '@finn/ui-utils';
import { useKeenSlider } from 'keen-slider/react';
import React, { useMemo, useState } from 'react';

import { TestimonialSlideMetadata } from '../Testimonial.types';
import { getSlidePosition } from '../Testimonial.utils';
import TestimonialSliderMobileSlide from './TestimonialSliderMobileSlide';

type Props = {
  slides: TestimonialSlideMetadata[];
};

const TestimonialSliderMobile: React.FC<Props> = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const displayedSlides = useMemo(
    () => (slides.length <= 2 ? [...slides, ...slides] : slides),
    [slides]
  );
  const keenSliderOptions = useMemo(
    () => ({
      loop: true,

      slides: {
        perView: 1.5,
      },

      slideChanged: (keenInstance) => {
        setCurrentIndex(keenInstance.track.details.rel);
      },
    }),
    []
  );

  const [sliderRef] = useKeenSlider(keenSliderOptions, []);

  return (
    <div ref={sliderRef}>
      <div
        className={cn(
          'keen-slider bg-[length:100%_50%]',
          'bg-gradient-to-b from-black to-black bg-bottom bg-no-repeat'
        )}
      >
        {displayedSlides.map((slide, index) => (
          <div
            className="keen-slider__slide pl-4"
            key={`${slide.name}${index >= slides.length ? '-repeated' : ''}`}
          >
            <TestimonialSliderMobileSlide
              slide={slide}
              position={getSlidePosition(currentIndex, index)}
            />
          </div>
        ))}
      </div>

      <div className="gap-4 bg-black px-4 py-9">
        <div className="flex flex-col justify-between gap-6">
          <div className="min-h-14">
            <h3 className="global-t5-semibold text-white">
              {displayedSlides[currentIndex]?.description}
            </h3>
          </div>

          <div className="flex flex-col gap-4">
            <h4 className="global-t6-semibold text-white">
              {displayedSlides[currentIndex]?.name}
            </h4>

            <span className="body-16-regular text-white">
              {displayedSlides[currentIndex]?.vehicle_model}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TestimonialSliderMobile);
