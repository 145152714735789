import { Button } from '@finn/design-system/atoms/button';
import { KeyboardArrowRight } from '@finn/design-system/icons/keyboard-arrow-right';
import React from 'react';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const TestimonialSliderDesktopNextButton = ({ onClick }: Props) => (
  <Button
    variant="ghost"
    size="md"
    aria-label="Nächste"
    className="bg-pewter hover:bg-steel active:bg-steel h-20 w-20 rounded-full transition-colors duration-100 ease-in-out"
    onClick={onClick}
  >
    <KeyboardArrowRight className="min-h-14 min-w-14 fill-white" />
  </Button>
);

export default TestimonialSliderDesktopNextButton;
